<template lang="pug">
	window
		template(slot="header")
			span Reset Password

		v-form
			p.
				Para poder reestabler su contraseña, por favor rellene los siguientes campos

			v-text-field(v-model="form.password"
										@blur="$v.form.password.$touch()"
										:error-messages="passwordError"
										label="Password"
										type="password"
										autocomplete="password"
										prepend-icon="mdi-lock")

			v-text-field(v-model="form.passwordConfirmation"
										@blur="$v.form.passwordConfirmation.$touch()"
										:error-messages="passwordConfirmationError"
										label="Password Confirmation"
										type="password"
										autocomplete="password-confirmation"
										prepend-icon="mdi-lock")

			v-layout(column align-center class="mt-4")
				v-flex
					v-btn(large color="primary" @click="request" :loading="loading", :disabled="invalid || loading") Restablecer

		div(class="text-xs-right mt-4")
			router-link(:to="{name: 'login'}") Iniciar sesion

</template>

<script>
import {required, sameAs, minLength} from 'vuelidate/lib/validators';

import Window from '@/modules/access/components/Window.component';

export default {
	name: 'ResetPassword',

	components: {
		Window
	},

	data() {
		return {
			form: {
				password: '',
				passwordConfirmation: ''
			}
		};
	},

	props: {
		loading: {
			type: Boolean,
			default: false
		},
		errorMessages: {
			type: Object,
			default() {
				return {
					required: 'Field is required',
					password: 'Password must be at least 6 characters',
					passwordMatch: 'Passwords need to match'
				};
			}
		}
	},

	computed: {
		passwordError() {
			const errors = [];
			const password = this.$v.form.password;

			if(password.$error) {
				const messages = this.errorMessages;
				if (!password.required) errors.push(messages.required)
				if (!password.minLength) errors.push(messages.password)
			}

			return errors
		},
		passwordConfirmationError() {
			const errors = [];
			const confirmPassword = this.$v.form.passwordConfirmation;

			if(confirmPassword.$error) {
				const messages = this.errorMessages;
				if (!confirmPassword.sameAsPassword) errors.push(messages.passwordMatch)
			}

			return errors
		},

		invalid() {
			return this.$v.form.$error;
		}
	},

	methods: {
		request() {

			this.$v.form.$touch();

			if (this.$v.form.$invalid) return;

			this.$emit('request', this.form.password);
		}
	},

	validations: {
		form: {
			password: {
				required,
				minLength: minLength(6)
			},
			passwordConfirmation: {
				sameAsPassword: sameAs('password')
			}
		}
	}
}
</script>




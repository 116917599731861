<template lang="pug">
	reset-password(@request="request" :loading="loading")
</template>
<script>

import ResetPassword from '@/modules/access/components/ResetPassword.component';
import firebase from '@/modules/common/firebase';

export default {
  name: 'AccessResetPassword',

	components: {
		ResetPassword
	},

	mounted() {
		if(!this.code) {
			this.$router.replace({name: 'login'});
		}
	},

  data() {
		return {
			loading: false,
			code: this.$route.query.oobCode
		};
	},

	methods: {
		request(password) {
			this.loading = true;
			firebase.auth().confirmPasswordReset(this.code, password)
				.then(this.onSuccessRequest)
				.catch(this.onFailureRequest);
		},

		onSuccessRequest() {
			this.loading = false;
			this.$router.push({name: 'login'})
		},

		onFailureRequest(error) {
			this.loading = false;

			this.$notify({
				title: 'Error',
				text: this.getErrorMessage(error.code),
				type: 'error'
			});
		},

		getErrorMessage(code) {
			let message;

			switch(code) {
				case 'auth/expired-action-code':
					message = "El link para reiniciar su contrasena ha expirado"
					break;
				case 'auth/invalid-action-code':
					message = "El link para reiniciar su contrasena es invalido"
					break;
				case 'auth/user-disabled':
					message = "El usuario ha sido bloqueado"
					break;
				case 'auth/user-not-found':
					message = "El usuario no ha podido ser encontrado"
					break;
				case 'auth/weak-password':
					message = "Su contraseña no es lo suficientemente fuerte"
					break;
				default:
					message = 'Error desconocido.'
			}

			return message;
		},


	}
}
</script>

<style scoped>

</style>


